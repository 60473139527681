.projects {
  width: 100%;
  opacity: 0;
  padding-top: 2rem;
  transform: translateY(-20px);
  animation: fadeInUp 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards 0.5s;

  h2 {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__content {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@keyframes fadeInUp {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
