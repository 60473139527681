.contacts__content__buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  transform: translateY(20px);
  opacity: 0;
  animation: fadeIn 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards 1.5s;

  @media (max-width: 799px) {
    gap: 10px;
    flex-direction: column;
  }
}

button {
  border: none;
  color: #fff;
  padding: 18px 36px;
  border-radius: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  @media (max-width: 799px) {
    padding: 12px 26px;
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
}

.linkedin {
  background: linear-gradient(to right, #0077b5, #005f9e);
  border: 2px solid #0077b5;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.resume {
  background: linear-gradient(to right, #20b499, #1a9c7f);
  border: 2px solid #20b499;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.github {
  background: linear-gradient(to right, #333, #000);
  border: 2px solid #333;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

button:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
