.hero__content__side1 {
  position: absolute;
  width: 65%;
  z-index: 6;
  margin-top: 60px;
  animation: slideAndFade 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;

  @media screen and (max-width: 1660px) {
    margin-top: 0;
  }

  @media screen and (max-width: 1100px) {
    position: relative;
    width: 100%;
  }

  &__text {
    width: 100%;

    h1 {
      margin-bottom: 1.5rem;
      width: 60%;

      @media screen and (max-width: 1660px) {
        width: 100%;
      }

      @media screen and (max-width: 1262px) {
        margin-bottom: 1rem;
      }
    }

    p {
      color: #d6d6d6;
      animation: fadeInUp 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards
        0.5s;
      width: 65%;

      @media screen and (max-width: 1100px) {
        width: 80%;
        padding: 0 0 2rem 0;
      }

      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

@keyframes slideAndFade {
  from {
    opacity: 0;
    transform: translateX(-50%) rotateY(-90deg) scale(0.2);
  }
  to {
    opacity: 1;
    transform: translateX(0) rotateY(0deg) scale(1);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
