.tech-stack__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 85px;
  margin: 15px;
  padding: 17px;
  border-radius: 55%;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation: fadeInUp 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.8s both;

  @media screen and (max-width: 980px) {
    width: 70px;
    height: 70px;
    padding: 14px;
    margin: 12px;
  }

  @media screen and (max-width: 490px) {
    width: 50px;
    height: 50px;
    padding: 12px;
    margin: 7px;
  }

  @media screen and (max-width: 320px) {
    width: 45px;
    height: 45px;
    margin: 5px;
  }

  &:hover {
    transform: translateY(-10px) scale(1.05);
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  }

  &__icon {
    width: 45px;
    height: 45px;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
