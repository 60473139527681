.tech-stack {
  text-align: center;
  animation: fadeInUp 1s ease-out 0.5s both;

  h2 {
    margin-bottom: 2rem;

    @media screen and (max-width: 400px) {
      margin-bottom: 0.5rem;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
