@import "./reset";
@import "./fonts";
@import "~font-awesome/css/font-awesome.css";

/* Main component START */
@import "../Components/Main/Styles/index";
@import "../Components/Main/Slider/Styles/slider";
@import "../Components/Main/FlyingObject/Styles/flyingObj";
/* Main component END */

/* Hero component START */
@import "../Components/Hero/HeroBox/Styles/heroBox";
@import "../Components/Hero/HeroContent/Styles/heroContent";
@import "../Components/Hero/HeroSide1/Styles/heroSide1";
@import "../Components/Hero/HeroSide2/Styles/heroSide2";
/* Hero component END */

/* Projects component START */
@import "../Components/Projects/ProjectsBox/Styles/projects-box";
@import "../Components/Projects/ProjectCard/Styles/project-card";
/* Projects component END */

/* About me component START */
@import "../Components/AboutMe/AboutMeBox/Styles/about-me-box";
@import "../Components/AboutMe/AboutMeContent/Styles/about-me-content";
@import "../Components/AboutMe/Tech/TechStackItem/Styles/tech-stack-items";
@import "../Components/AboutMe/Tech/TechStackList/Styles/tech-stack-item";
/* About me component END */

/* Contacts component START */
@import "../Components/Contacts/ContactsBox/Styles/contacts-box";
@import "../Components/Contacts/ContactsBoxContent/Styles/contacts-box-content";
@import "../Components/Contacts/ContactButtons/Styles/contacts-buttons";
/* Contacts component END */

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  font-family: $text-font;
  overflow: hidden;
}

.hero-container {
  margin: 0 auto;
  padding: 1rem 12rem;

  @media screen and (max-width: 1450px) {
    padding: 0 3.5rem;
  }

  @media screen and (max-width: 1150px) {
    padding: 2rem;
  }
}

.container {
  margin: 0 auto;
  padding: 5rem 20rem;
}

h1 {
  font-size: 7rem;
  font-weight: 800;
  font-family: $title-font;
  color: #fff;

  @media screen and (max-width: 1660px) {
    font-size: 5.5rem;
  }

  @media screen and (max-width: 1262px) {
    font-size: 5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 4rem;
  }
}

h2 {
  font-size: 3rem;
  color: #fff;

  @media screen and (max-width: 1660px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 1262px) {
    font-size: 1.8rem;
  }
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: 400;
  color: #dbdbdb;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.logo-color {
  font-weight: 700;
  color: #0073e6;
}
