.about-me__content__texts {
  text-align: center;
  animation: fadeInUp 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) both;

  h1 {
    margin-bottom: 3rem;

    @media screen and (max-width: 480px) {
      margin-bottom: 1.5rem;
      font-size: 3rem;
    }
  }

  p {
    margin-bottom: 2rem;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}
