.project-card {
  position: relative;
  display: flex;
  width: 800px;
  height: 600px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 1800px) {
    height: 500px;
  }

  @media screen and (max-width: 890px) {
    flex-direction: column;
  }

  &:hover {
    width: 1100px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 890px) {
      height: 100%;

      .project-card__project-info {
        opacity: 1;
        transform: translateY(0%);
      }
    }
  }

  img {
    width: 800px;
    height: 600px;
    object-fit: cover;
    object-position: top;
    transition: object-position 7s ease-in-out, width 0.3s ease-in-out;

    @media screen and (max-width: 890px) {
      width: 100%;
      transition: object-position 7s ease-in-out, height 0.3s ease-in-out;
    }
  }

  &:hover img {
    object-position: bottom;
    width: 600px;

    @media screen and (max-width: 1200px) {
      width: 450px;
    }

    @media screen and (max-width: 840px) {
      width: 100%;
    }

    @media screen and (max-width: 890px) {
      width: 100%;
      height: 300px;
    }

    @media screen and (max-width: 500px) {
      height: 150px;
    }
  }

  &__project-info {
    background: linear-gradient(135deg, #266489, #5232ac);
    color: white;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: transform 0.3s;
    margin-top: -3px;
    opacity: 1;

    @media screen and (max-width: 890px) {
      transform: translateY(100%);
      opacity: 1;
      transition: 0.4s ease-in-out;
    }

    @media screen and (max-width: 500px) {
      padding: 10px;
    }

    &--info-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-size: 2rem;
      }

      p {
        @media screen and (max-width: 1078px) {
          font-size: 1rem;
        }
      }

      &--pic-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 80px;

        h3 {
          font-size: 1.5rem;

          @media screen and (max-width: 1078px) {
            font-size: 1.2rem;
          }
        }

        &--icon-container {
          width: 2.5rem;
          height: 2.5rem;
          background: linear-gradient(135deg, #f0f0f0, #f8f8f8);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-left: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.221);
          transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
          cursor: pointer;

          @media screen and (max-width: 1078px) {
            width: 2rem;
            height: 2rem;
          }

          @media screen and (max-width: 500px) {
            width: 1.7rem;
            height: 1.7rem;
          }

          img {
            width: 60% !important;
            height: 60% !important;
            object-fit: contain;
          }

          &:hover {
            background: linear-gradient(135deg, #ff6b6b, #ffbb00);
            transform: scale(1.05);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.311);
            object-position: bottom;
          }
        }
      }

      &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        a {
          width: 2.5rem;
          height: 2.5rem;
          background: linear-gradient(135deg, #f0f0f0, #f8f8f8);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.221);
          cursor: pointer;
          color: #2b2b2b;
          font-size: 1.3rem;
          text-decoration: none;
          transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

          @media screen and (max-width: 1078px) {
            width: 2rem;
            height: 2rem;
          }

          &:hover {
            background: linear-gradient(135deg, #ff6b6b, #ffbb00);
            transform: scale(1.05);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.311);
            color: #c9c9c9;
          }

          svg {
            @media screen and (max-width: 1078px) {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }
    }
  }
}
