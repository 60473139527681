.hero__content__side2 {
  left: 40%;
  margin-top: 115px;
  position: relative;
  max-width: 75%;
  height: auto;
  padding-right: 12rem;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeIn 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards,
    float 4s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
  z-index: 5;

  @media screen and (max-width: 1450px) {
    max-width: 70%;
    margin-top: 70px;
    padding-left: 7rem;
  }

  @media screen and (max-width: 1250px) {
    padding-left: 1rem;
  }

  @media screen and (max-width: 1100px) {
    margin-top: 0;
    min-width: 60%;
    height: 200px;
    left: auto;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
