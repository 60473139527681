:root {
  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: 0;
  --swiper-pagination-bullet-inactive-color: #ffffff;
  --swiper-pagination-color: #333;
  --swiper-pagination-bullet-inactive-opacity: 0.7;
}

.swiper-pagination {
  display: flex;
  justify-content: row;
  justify-content: flex-end;
  padding-right: 15px;
}

.main {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__top {
    display: inline-flex;
    flex-direction: row;
    padding: 10px;

    &__left {
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: #353535;
      border: none;
      border-radius: 50px;
      padding: 10px 20px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 999999;
      animation: subtlePulse 4s ease-in-out infinite;

      @media screen and (max-width: 450px) {
        padding: 8px 16px;
      }

      h1 {
        color: white;
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0;

        @media screen and (max-width: 450px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  &__bottom {
    margin-top: 1rem;
  }
}

@keyframes subtlePulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
