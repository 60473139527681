.about-me {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5rem;

  @media (max-width: 799px) {
    padding-top: 2rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
