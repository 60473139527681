.contacts__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  &__title {
    h1 {
      margin-bottom: 1.5rem;
      transform: translateY(-20px);
      opacity: 0;
      animation: slideUp 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards 0.5s;

      @media screen and (max-width: 480px) {
        font-size: 3rem;
        margin-bottom: 1.1rem;
      }
    }

    h2,
    p {
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 1.5rem;
      opacity: 0.9;
      transform: translateY(20px);
      opacity: 0;
      animation: slideUp 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards 1s;

      @media screen and (max-width: 480px) {
        margin-bottom: 1.1rem;
      }
    }
  }
}

@keyframes slideUp {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
