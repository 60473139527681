.flyingObj {
  position: absolute;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 8px 24px;
  animation: reshape 8s linear infinite;
}

@keyframes reshape {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
